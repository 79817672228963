import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { Image, SectionTitle, TestimonialsCard } from '../../atoms';
import Col from '../../ui/gridSystem/Col';
import Row from '../../ui/gridSystem/Row';
import {
  CardComponent,
  ContainerAboutUs,
  Article,
  CentralImage,
  Title
} from './style';
import { PageArticle } from '../../components';
import { GET_TESTIMONIALS } from '../../redux/actions/testimonials';
import Overpass from '../../ui/typography/overpass';

const Testimonials = ({
  mediaIsTablet,
  mediaIsPhone,
  getTestimonials,
  testimonials
}) => {
  useEffect(() => {
    getTestimonials();
  }, [getTestimonials]);

  const testimonialsInfo = Object.keys(testimonials).reduce((acc, key) => {
    switch (key) {
      case 'initial_article':
        if (!testimonials[key]) return acc;
        return {
          ...acc,
          [key]: testimonials[key][0]
        };
      case 'witenesses':
        if (!testimonials[key]) return acc;
        return {
          ...acc,
          [key]: testimonials[key].map(item => item.data)
        };
      case 'student_witnesses':
      case 'company_witnesses':
        if (!testimonials[key]) return acc;
        return {
          ...acc,
          [key]: testimonials[key][0].data
        };
      default:
        return {
          ...acc,
          [key]: testimonials[key]
        };
    }
  }, {});

  const {
    title,
    initial_article,
    central_image,
    witenesses,
    student_witnesses,
    company_witnesses
  } = testimonialsInfo;

  const getStudentRows = () => {
    if (!Math.ceil(student_witnesses?.witnesses?.length)) return 0;
    return Math.ceil(student_witnesses?.witnesses?.length / 3) * 3;
  };

  const getCardType = index => {
    const types = [
      TestimonialsCard.TYPE.PRIMARY,
      TestimonialsCard.TYPE.TERTIARY,
      TestimonialsCard.TYPE.SECONDARY,
      TestimonialsCard.TYPE.SECONDARY,
      TestimonialsCard.TYPE.PRIMARY,
      TestimonialsCard.TYPE.TERTIARY,
      TestimonialsCard.TYPE.TERTIARY,
      TestimonialsCard.TYPE.SECONDARY,
      TestimonialsCard.TYPE.PRIMARY
    ];
    return types[index % 9];
  };

  return (
    <ContainerAboutUs>
      <SectionTitle title={title} />
      {initial_article && (
        <Article>
          <PageArticle
            title={initial_article?.data?.title}
            body={initial_article?.data?.body}
          />
        </Article>
      )}
      {!mediaIsPhone && central_image && (
        <CentralImage>
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              className={mediaIsPhone || mediaIsTablet ? 'p-0' : ''}
            >
              <Image imageUrl={central_image?.url} lines={false} />
            </Col>
          </Row>
        </CentralImage>
      )}
      {student_witnesses && (
        <CardComponent>
          <Title>
            <Overpass
              type={
                mediaIsPhone ? 'sectionHeaderTitleMobile' : 'sectionHeaderTitle'
              }
            >
              {student_witnesses?.title}
            </Overpass>
          </Title>
          <Row>
            {student_witnesses?.witnesses?.map(({ data }, index) => (
              <Col
                md={mediaIsTablet ? 6 : 4}
                sm={12}
                key={`testimonial-${index}`}
                className="p-0"
              >
                <TestimonialsCard data={data} type={getCardType(index)} />
              </Col>
            ))}
          </Row>
        </CardComponent>
      )}
      {company_witnesses && (
        <CardComponent>
          <Title>
            <Overpass
              type={
                mediaIsPhone ? 'sectionHeaderTitleMobile' : 'sectionHeaderTitle'
              }
            >
              {company_witnesses?.title}
            </Overpass>
          </Title>
          <Row>
            {company_witnesses?.witnesses?.map(({ data }, index) => (
              <Col
                md={mediaIsTablet ? 6 : 4}
                sm={12}
                key={`testimonial-${index}`}
                className="p-0"
              >
                <TestimonialsCard
                  data={data}
                  type={getCardType(index + getStudentRows())}
                />
              </Col>
            ))}
          </Row>
        </CardComponent>
      )}
      {witenesses && (
        <CardComponent>
          <Row>
            {witenesses?.map((data, index) => (
              <Col
                md={mediaIsTablet ? 6 : 4}
                sm={12}
                key={`testimonial-${index}`}
                className="p-0"
              >
                <TestimonialsCard data={data} type={getCardType(index)} />
              </Col>
            ))}
          </Row>
        </CardComponent>
      )}
    </ContainerAboutUs>
  );
};
Testimonials.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,

  // HOC (connect, state)
  testimonials: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getTestimonials: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { testimonials } = state.testimonials;
    return {
      testimonials
    };
  },
  dispatch => ({
    getTestimonials: () => dispatch({ type: GET_TESTIMONIALS._REQUEST })
  })
)(withMediaQueries(Testimonials));
