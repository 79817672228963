const colors = {
  primary: {
    blue5: 'rgba(62, 63, 108, 1)',
    blue4: 'rgba(62, 63, 108, 0.8)',
    blue3: 'rgba(62, 63, 108, 0.6)',
    blue2: 'rgba(62, 63, 108, 0.4)',
    blue1: 'rgba(62, 63, 108, 0.2)',
    black5: 'rgba(0, 0, 0, 1)',
    black4: 'rgba(0, 0, 0, 0.8)',
    black3: 'rgba(0, 0, 0, 0.6)',
    black2: 'rgba(0, 0, 0, 0.4)',
    black1: 'rgba(0, 0, 0, 0.2)',
    red: '#eb5c4d',
    yellow: '#ffca7d',
    white: '#fff'
  },
  secondary: {
    blueHeaderDark: '#373660',
    blueHeaderLight: '#6362ae',
    lightViolet: '#65658a',
    gray: '#666666',
    grayLighter: '#979797',
    grayDark: '#333333',
    manatee: '#8B8CA7',
    grayLight: '#999999',
    blueNumber: '#373660',
    bgYellowLight: '#fff9f2',
    bgGrayLight: '#fafafb',
    grayLightest: '#c9ccd4',
    orange: '#eb6153',
  }
};

export default colors;
