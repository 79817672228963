import styled from 'styled-components';

export const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  
  .slick-slider {
    li {
      button {
        &::before {
          font-size: 10px;
          color: ${({ theme }) => theme.colors.secondary.manatee}
          opacity: 1;
        }
      }
      
      &.slick-active {
        button {
          &::before {
            color: ${({ theme }) => theme.colors.primary.red}
            opacity: 1;
          }
        }
      }
    }
  }
`;
