import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ImageContainer, ImageItem, ImageDecoration } from '../Image/style';
import { VideoPlay } from './style';
import { MODAL_OPEN } from '../../redux/actions';
import { VideoModal } from '../../components/Modals';
import Icon from '../Icon';
import theme from '../../ui/theme';
import { withMediaQueries } from '../../hoc/withMediaQueries';

const Video = ({
  imageUrl, position, x, y, size,
  maxWidth, lines, videoUrl, openVideoModal,
  mediaIsTablet, mediaIsPhone
}) => {
  const decoration = lines && (x !== 'none') && (y !== 'none');
  return (
    <>
      <ImageContainer maxWidth={maxWidth}>
        <ImageItem src={imageUrl} />
        <VideoPlay onClick={() => openVideoModal()}>
          <Icon type="icon-play" color={theme.colors.primary.white} size={30} />
        </VideoPlay>
        { decoration && <ImageDecoration position={position} x={x} y={y} size={size} />}
      </ImageContainer>
      <VideoModal videoUrl={videoUrl} closeIcon />
    </>
  );
};

Video.POSITION = {
  OUTSIDE: 'outside',
  INSIDE: 'inside'
};

Video.X = {
  NONE: 'none',
  LEFT: 'left',
  RIGHT: 'right'
};

Video.Y = {
  NONE: 'none',
  TOP: 'top',
  BOTTOM: 'bottom'
};

Video.SIZE = {
  SMALL: 'small',
  BIG: 'big',
  XSMALL: 'xsmall'
};

Video.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  position: PropTypes.oneOf(Object.values(Video.POSITION)),
  x: PropTypes.oneOf(Object.values(Video.X)),
  y: PropTypes.oneOf(Object.values(Video.Y)),
  size: PropTypes.oneOf(Object.values(Video.SIZE)),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lines: PropTypes.bool,
  videoUrl: PropTypes.string,
  openVideoModal: PropTypes.func,
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired
};

Video.defaultProps = {
  position: Video.POSITION.OUTSIDE,
  x: Video.X.NONE,
  y: Video.Y.NONE,
  lines: true,
  size: Video.SIZE.BIG
};

export default connect(
  () => ({}),
  dispatch => ({
    openVideoModal: () => dispatch({ type: MODAL_OPEN, id: VideoModal.id })
  })
)(withMediaQueries(Video));
