import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuContainer, Bar1, Bar2, Bar3
} from './style';

const MenuButton = ({ isOpen, onClick }) => (
  <MenuContainer isOpen={isOpen} onClick={onClick}>
    <Bar1 />
    <Bar2 />
    <Bar3 />
  </MenuContainer>
);

MenuButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default MenuButton;
