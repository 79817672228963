import {
  call, put, takeLatest, select
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';
import { GET_NEWS, GET_NEWS_DETAIL } from '../actions/news';
import news from '../../api/news';
import routes from '../../routes';

function* getNews({ filters: params, new_set = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { list = [] } = yield select(state => state.news);
    if (list.length <= 0 || new_set) {
      const res = yield call(news.getNews, { params });
      const { data: { data: { filters = {}, items = [] } } = {} } = res;
      yield put({ type: GET_NEWS._SUCCESS, filters, items });
    }
  } catch (error) {
    yield put({ type: GET_NEWS._ERROR, error });
    console.log('sagas > news > getNews', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewsWatcher() {
  yield takeLatest(GET_NEWS._REQUEST, getNews);
}

function* getNewsDetails({ news_id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const res = yield call(news.getNewsItem, { news_id });
    const { data: { data } = {} } = res;
    if (!data) yield put(push(`${routes.news.path}`));
    yield put({ type: GET_NEWS_DETAIL._SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_NEWS_DETAIL._ERROR, error });
    console.log('sagas > news > getNewsDetails', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewsDetailsWatcher() {
  yield takeLatest(GET_NEWS_DETAIL._REQUEST, getNewsDetails);
}

export default [
  getNewsWatcher(),
  getNewsDetailsWatcher()
];
