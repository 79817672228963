import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import NavBarDesktop from './desktop';
import NavBarTablet from './tablet';

const NavBar = ({
  current_path, navbar, mediaIsPhone, mediaIsTablet
}) => {
  const { menu: menuObject = [] } = navbar;
  const menu = menuObject.map(item => item.data);
  const { social_buttons: socials = {} } = navbar;
  const { signup_button: signup = {} } = navbar;
  const { signin_button: signin = {} } = navbar;

  if ((mediaIsPhone || mediaIsTablet) && menu.length > 0) {
    return <NavBarTablet current_path={current_path} menu={menu || []} signup={signup} signin={signin} mediaIsPhone={mediaIsPhone} socials={socials} />;
  }

  return <NavBarDesktop current_path={current_path} menu={menu || []} socials={socials} signup={signup} signin={signin} />;
};

NavBar.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,

  // HOC (connect, state)
  current_path: PropTypes.string.isRequired,
  navbar: PropTypes.object
};

export default connect(
  state => {
    const { current_path } = state.app;
    const { navbar } = state.app;
    return {
      current_path,
      navbar
    };
  }
)(withMediaQueries(NavBar));
