import styled from 'styled-components';

export const RecentNewsContainer = styled.div`
  height: 400px;
  width: 280px;
  
  .slick-slider {
    .slick-dots {
      bottom: 12px;
      width: 50%;
      right: -10px;
      
      li {
        margin: 0;
      }
    }
  }
  
  @media ${props => props.theme.device.tablet} {
    height: 180px;
    width: 100vw;
    
    .slick-slider {
      .slick-dots {
        width: 25%;
      }
    }
  }
  
  @media ${props => props.theme.device.mobile} {
    height: 400px;
    width: 100vw;
    
    .slick-slider {
      .slick-dots {
        width: 40%;
      }
    }
  }
`;
