import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { RowDataContainer, ColumnData } from './style';
import Overpass from '../../ui/typography/overpass';
import { Row, Col } from '../../ui/gridSystem';
import HtmlRaw from '../HtmlRaw';

const RowData = ({
  title, data
}) => (
  <RowDataContainer>
    <Row>
      <Col lg={4} md={6} sm={11}>
        <Overpass type="courseOnlineFooterTitle">{title}</Overpass>
      </Col>
      <Col lg={{ size: 6, offset: 1 }} md={12} sm={12}>
        <Row>
          {data.map((item, i) => (
            <ColumnData key={i} lg={{ size: 5, offset: 1 }} md={6} sm={12}>
              <Overpass type="authorName" htmlAttribute="h4">{item.title}</Overpass>
              <Overpass type="projectTextColumn">
                <HtmlRaw html={item.body} />
              </Overpass>
            </ColumnData>
          ))}
        </Row>
      </Col>
    </Row>
  </RowDataContainer>
);
RowData.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array
};

export default connect(
)(withMediaQueries(RowData));
