import styled from 'styled-components';
import { Row, Col } from '../../ui/gridSystem';

export const SectionHeaderContainer = styled(Row).attrs(() => ({
  className: 'section_header'
}))``;
export const SectionHeaderLeft = styled(Col)`
  @media ${props => props.theme.device.tablet} {
    margin-bottom: 45px;
  }

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 60px;
  }

  &.ArticleText-SectionHeaderLeft {
    @media ${props => props.theme.device.tablet},
      @media ${props => props.theme.device.mobile} {
      margin-bottom: 40px;
    }
  }

  &.FaqText-SectionHeaderLeft {
    margin-bottom: 20px;
    @media ${props => props.theme.device.desktop} {
      margin-bottom: unset;
    }
  }
`;
export const SectionHeaderRight = styled(Col)`
  > div {
    margin: 0 auto;
  }
  ${({ alignRight }) =>
    alignRight &&
    `
    > div {
      margin-left: auto;
      margin-right: 0;
    }
  `};
`;

export const TitleText = styled.div``;
export const Title = styled.div`
  margin-bottom: 40px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 30px;
  }
`;
export const Body = styled.div``;
