import React from 'react';
import PropTypes from 'prop-types';
import Overpass from '../../ui/typography/overpass';
import { ContainerDetailText } from './style';
import { HtmlRaw } from '../../components';

const CourseOnlineDetail = ({
  title, body
}) => (
  <ContainerDetailText>
    <Overpass type="courseOnlineDetailTitle">{title}</Overpass>
    <Overpass type="courseOnlineDetailBody"><HtmlRaw html={body} /></Overpass>
  </ContainerDetailText>
);

CourseOnlineDetail.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

export default CourseOnlineDetail;
