import styled from 'styled-components';

export const PlanBoxContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: ${props => (props.first ? 'flex-end' : 'flex-start')};
    background-color: ${props => (props.color ? props.color : 'transparent')};
`;

export const PlanBoxContent = styled.div`
    width:100%;
    max-width: unset;
    padding: 80px 84px;
    z-index:9;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @media ${props => props.theme.device.mobile} {
        max-width: unset;
        padding: 44px 29px 107px;
    }

    div{
        &:nth-child(2){
            margin: 30px 0;
            @media ${props => props.theme.device.mobile} {
                margin: 20px 0;
            }
        }
    }
`;

export const PlanBoxImage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(${props => (props.image ? `${props.image}` : '')});
    opacity: 0.2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
`;

export const ButtonContainer = styled.div`
    margin-top: 100px;
    @media ${props => props.theme.device.tablet} {
        margin-top: 68px;
    }
    @media ${props => props.theme.device.mobile} {
        margin-top: 42px;
    }
`;
