import React from 'react';
import PropTypes from 'prop-types';
import {
  PlanBoxContainer,
  PlanBoxImage,
  PlanBoxContent,
  ButtonContainer
} from './style';
import { ButtonInterface } from '../../interface/button';
import { Button } from '..';
import Overpass from '../../ui/typography/overpass';
import { HtmlRaw } from '../../components';

const PlanBox = ({
  image, label, title, text, button, color, index
}) => (
  <PlanBoxContainer first={index === 0} color={color}>
    <PlanBoxImage image={image} />
    <PlanBoxContent>
      <Overpass type="planBoxLabel">{label}</Overpass>
      <Overpass type="planBoxTitleMobile">{title}</Overpass>
      <Overpass type="planBoxText"><HtmlRaw html={text} /></Overpass>
      { button && (
        <ButtonContainer>
          <Button onClick={button.onClick} btnType={Button.TYPE.TERTIARY} btnText={button.btnText} />
        </ButtonContainer>
      )}
    </PlanBoxContent>
  </PlanBoxContainer>
);

PlanBox.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  button: ButtonInterface,
  image: PropTypes.string,
  color: PropTypes.string,
  index: PropTypes.number
};

export default PlanBox;
