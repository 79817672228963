import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '..';
import Overpass from '../../ui/typography/overpass';
import { ContainerDetailIcon } from './style';
import colors from '../../ui/theme/partials/colors';
import { HtmlRaw } from '../../components';

const DetailIcon = ({
  text, icon
}) => (
  <ContainerDetailIcon>
    <Icon color={colors.primary.blue5} size={30} type={`${icon}`} />
    <Overpass type="detailIcon"><HtmlRaw html={text} /></Overpass>
  </ContainerDetailIcon>
);

DetailIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default DetailIcon;
