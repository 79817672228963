import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../ui/gridSystem';
import Modal, { stopEvent } from '../Modal';

import {
  ModalGlobalContainer, ModalWrapper
} from '../Modal.style';

import { VideoContainer, CloseModal } from './style';
import { MODAL_CLOSE } from '../../../redux/actions';
import MenuButton from '../../../atoms/MenuButton';

const modalId = 'VideoModal';

const VideoModal = ({ onClose, videoUrl, closeIcon }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        {closeIcon && (
          <CloseModal>
            <MenuButton isOpen onClick={() => onClose()} />
          </CloseModal>
        )}
        <ModalGlobalContainer
          onMouseDown={stopEvent}
          onClick={stopEvent}
          widthUnit="%"
          width="90"
          heightUnit="%"
          height="90"
        >
          <VideoContainer>
            <video autoPlay controls>
              <source src={videoUrl} type="video/mp4" />
              <track src={videoUrl} kind="captions" />
            </video>
          </VideoContainer>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

VideoModal.propTypes = {

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,

  videoUrl: PropTypes.string,
  closeIcon: PropTypes.bool
};

VideoModal.id = modalId;

export default connect(
  () => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(VideoModal);
