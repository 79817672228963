import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  position: relative;
`;

const SetModalSize = (size, unit, value) => {
  const unitValue = unit || 'px';
  const defaultValue = value === 'width' ? '644' : '459';
  const sizeValue = size || defaultValue;
  return sizeValue + unitValue;
};

export const ModalGlobalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary.black};
  border-radius: 6px;
  pointer-events: auto;
  position: relative;
  user-select: none;
  padding: 26px;
  width: ${props => SetModalSize(props.width, props.widthUnit, 'width')}
  height: ${props => SetModalSize(props.height, props.heightUnit, 'height')}
  margin: 0 auto;
  @media ${props => props.theme.device.tablet} {
    width: ${props => (props.widthtablet && `${props.widthtablet}px`)};
  }
  
   @media ${props => props.theme.device.mobile} {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 20px 40px;
  }
`;
