import styled, { css } from 'styled-components';
import Button from './index';

const funzioneCalcoloStile = ({ theme, btnType, disabled }) => {
  let style;
  switch (btnType) {
    case Button.TYPE.PRIMARY:
      style = css`
        background-color: ${theme.colors.primary.red};
        color: ${theme.colors.primary.white};
        border: transparent;
        ${disabled && css`
          opacity: 0.5;
        `}
      `;
      break;
    case Button.TYPE.SECONDARY:
      style = css`       
        color: ${theme.colors.primary.red};
        background-color: transparent;
        border: 1px solid ${theme.colors.primary.red};
        ${disabled && css`
          opacity: 0.5;
        `}
      `;
      break;
    case Button.TYPE.TERTIARY:
      style = css`       
        color: ${theme.colors.primary.white};
        background-color: transparent;
        border: 1px solid ${theme.colors.primary.white};
        ${disabled && css`
          opacity: 0.5;
        `}
      `;
      break;
    case Button.TYPE.FOURTH:
      style = css`       
        color: ${theme.colors.primary.red};
        background-color: ${theme.colors.primary.white};
        border: 1px solid ${theme.colors.primary.red};
        ${disabled && css`
          opacity: 0.5;
        `}
      `;
      break;

    default:
      break;
  }

  return style;
};

export const ButtonContainer = styled.button` 
  position: relative;
  min-width: ${({ minWidth }) => `${minWidth}px`};
  min-height: ${({ minHeight }) => `${minHeight}px`};
  white-space: nowrap;
  transition: all 0.125s ease-in;
  -ms-transition: all 0.125s ease-in;
  text-transform: uppercase;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${props => funzioneCalcoloStile(props)};
`;
