import React from 'react';
import { connect } from 'react-redux';
import { Container } from './style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { Carousel, RecentNews } from '../../components';
import Overpass from '../../ui/typography/overpass';
import {
  Button, NewsCard, Icon, DetailText, DetailIcon
} from '../../atoms';
import CollaboratosCard from '../../atoms/CollaboratorsCard';
import Col from '../../ui/gridSystem/Col';
import Row from '../../ui/gridSystem/Row';

const Sample = ({
  mediaIsPhone, mediaIsTablet
}) => {
  const settings = {
    slidesToShow: 2
  };

  const news = [
    {
      title: 'Trovare lavoro con le palestre digitali. Chi le frequenta ha un contratto', date: '09-06-2018', id: 1, img: 'https://picsum.photos/280/260?grayscale'
    },
    {
      title: 'Trovare lavoro con le palestre digitali. Chi le frequenta ha un contratto', date: '09-06-2018', id: 1, img: 'https://picsum.photos/280/260?grayscale'
    },
    {
      title: 'Trovare lavoro con le palestre digitali. Chi le frequenta ha un contratto', date: '09-06-2018', id: 1, img: 'https://picsum.photos/280/260?grayscale'
    }
  ];
  const employees = [
    {
      url: 'https://iit.it/files/peoplePhotos/506e5be9f8f4e8fda7ac93fb3da552e2_250.jpg', name: 'Mario Romolo', task: 'resposible of special projects', companyName: 'Italy-Google', description: 'A Palestre mi piace portare la mia testimonianza su come gestiamo in Google le tematiche di Digital Marketing e di sviluppo tecnologico.', cardType: 'secondary'
    },
    {
      url: 'https://iit.it/files/peoplePhotos/506e5be9f8f4e8fda7ac93fb3da552e2_250.jpg', name: 'Mario Romolo', task: 'resposible of special projects', companyName: 'Italy-Google', description: 'A Palestre mi piace portare la mia testimonianza su come gestiamo in Google le tematiche di Digital Marketing e di sviluppo tecnologico.', cardType: 'primary'
    },
    {
      url: 'https://iit.it/files/peoplePhotos/506e5be9f8f4e8fda7ac93fb3da552e2_250.jpg', name: 'Mario Romolo', task: 'resposible of special projects', companyName: 'Italy-Google', description: 'A Palestre mi piace portare la mia testimonianza su come gestiamo in Google le tematiche di Digital Marketing e di sviluppo tecnologico.', cardType: 'tertiary'
    },
    {
      url: 'https://iit.it/files/peoplePhotos/506e5be9f8f4e8fda7ac93fb3da552e2_250.jpg', name: 'Mario Romolo', task: 'resposible of special projects', companyName: 'Italy-Google', description: 'A Palestre mi piace portare la mia testimonianza su come gestiamo in Google le tematiche di Digital Marketing e di sviluppo tecnologico.', cardType: 'tertiary'
    }
  ];
  return (
    <Container>
      Sample page desktop
      <Carousel settings={settings}>
        <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        <div>
          <h3>5</h3>
        </div>
        <div>
          <h3>6</h3>
        </div>
      </Carousel>
      <Overpass configuration={{ fontSize: 22 }} type="thin">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="thinItalic">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="extraLight">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="extraLightItalic">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="light">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="lightItalic">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }}>Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="regularItalic">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="semiBold">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="semiBoldItalic">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="bold">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="boldItalic">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="extraBold">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="extraBoldItalic">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="black">Test font style</Overpass>
      <Overpass configuration={{ fontSize: 22 }} type="blackItalic">Test font style</Overpass>
      <Button btnType={Button.TYPE.PRIMARY} btnText="Primary button" />
      <Button btnType={Button.TYPE.SECONDARY} btnText="Secondary button" />
      <Button btnType={Button.TYPE.TERTIARY} btnText="Tertiary button" />
      <NewsCard news={news[0]} />
      <NewsCard news={news[0]} isCarousel />
      <RecentNews news={news} />
      <Icon size={30} type="icon-twitter" />
      <Icon size={30} type="icon-facebook" />
      <Icon size={30} type="icon-linkedin" />
      <Icon size={30} type="icon-aziende" />
      <Icon size={30} type="icon-disegno" />
      <Icon size={30} type="icon-chat" />
      <DetailIcon text="detail icon" icon="chat" />
      <DetailText text="detail text" number="3000" />
      <Row>
        {(mediaIsTablet || mediaIsPhone) ? employees.map(data => <Col md={12}><CollaboratosCard data={data} /></Col>) : employees.map(data => <Col md={6}><CollaboratosCard data={data} /></Col>)}

      </Row>
    </Container>
  );
};
Sample.propTypes = {};
export default connect(
  () => ({})
)(withMediaQueries(Sample));
