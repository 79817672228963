import React from 'react';
import PropTypes from 'prop-types';
import { ContainerPlan } from './style';
import { PlanBox } from '../../../atoms';
import theme from '../../../ui/theme';
import { composeDefaultButton } from '../../../utils/common';


const colours = [theme.colors.primary.yellow, theme.colors.primary.blue5];

const PlanMobile = ({
  plans = []
}) => (
  <ContainerPlan>
    {[...plans].map((plan, i) => (
      <PlanBox
        key={i}
        image={plan.image.url}
        index={i}
        label={plan.header}
        text={plan.content[0].data?.body}
        title={plan.content[0].data?.title}
        color={colours[i]}
        button={composeDefaultButton(plan.button?.title, plan.button?.uri)}
      />
    ))}
  </ContainerPlan>
);

PlanMobile.propTypes = {
  plans: PropTypes.array
};

export default PlanMobile;
