import styled from 'styled-components';

export const PartnerDetailContainer = styled.section`
    margin-top: 50px;
    
    .section-title {
      margin-bottom: 20px;
    }
`;

export const PartnerDetailEditorialContent = styled.section`
    margin: 50px 0 25px;
`;

export const PartnerDetailsBodyItem = styled.div`

  &.item {
    &-article{
      margin-bottom: 91px;
    }
    &-images{
      margin-bottom: 80px;
    }
    &-file_article{
      margin-bottom: 80px;
    }
    &-item-witnesses{
      margin-bottom: 50px;
    }
    &-title_articles{
      margin-bottom: 86px;
    }
  }
`;
