import React from 'react';
import PropTypes from 'prop-types';

import { GlobalOverLay } from '../../atoms';
import { PageContainer, PageContent } from './styles';

import { Container } from '../../ui/gridSystem';
import NavBar from '../NavBar';
import Footer from '../Footer';

const GlobalLayout = ({
  children
}) => (
  <>
    <NavBar />
    <PageContainer>
      <GlobalOverLay />
      <Container style={{ position: 'relative' }}>
        <PageContent className="page-content">
          {children}
        </PageContent>
      </Container>
    </PageContainer>
    <Footer />
  </>
);

GlobalLayout.propTypes = {
  children: PropTypes.node
};

export default GlobalLayout;
