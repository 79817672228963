import produce from 'immer';
import { GET_HOME } from '../actions/home';

const initialState = {
  home: {}
};

const homeReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_HOME._SUCCESS: {
      const { home } = action;
      draft.home = { ...home };
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default homeReducer;
