import produce from 'immer';
import { GET_NEWS, GET_NEWS_DETAIL } from '../actions/news';

const initialState = {
  list: [],
  filters: {
    categories: [],
    months: []
  },
  selected_news: {}
};

const newsReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_NEWS._SUCCESS: {
      const { filters, items } = action;
      draft.list = [...items];
      draft.filters.categories = filters.categories.map(item => ({ label: item.name, value: item.id }));
      draft.filters.months = filters.months.map(item => ({ label: item.value, value: item.key }));
      break;
    }
    case GET_NEWS_DETAIL._SUCCESS: {
      const { data } = action;
      draft.selected_news = { ...data };
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default newsReducer;
