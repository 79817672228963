import styled from 'styled-components';

export const VideoPlay = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 10;
  top: 0;

  .icon {
    &:after {
      content: '';
      position: absolute;
      width: 60px;
      height: 60px;
      left: -15px;
      top: -15px;
      background-color: ${props => props.theme.colors.primary.red};
      z-index: -1;
      border-radius: 100%;
    }
  }
`;
