import React from 'react';

import PropTypes from 'prop-types';
import { Image, Video, Button } from '../../atoms';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { ImageTextRow } from './style';
import { Col } from '../../ui/gridSystem';
import Overpass from '../../ui/typography/overpass';
import HtmlRaw from '../HtmlRaw';

const ImageConfigurationDefault = {
  x: Image.X.LEFT,
  y: Image.Y.TOP,
  position: Image.POSITION.OUTSIDE,
  size: Image.SIZE.SMALL
};

const ImageConfigurationReverse = {
  x: Image.X.RIGHT,
  y: Image.Y.TOP,
  position: Image.POSITION.OUTSIDE,
  size: Image.SIZE.XSMALL
};

const ImageText = ({
  title, text, image, button, video, reverse, onclick
}) => {
  const ImageConfiguration = reverse ? ImageConfigurationReverse : ImageConfigurationDefault;
  return (
    <ImageTextRow reverse={reverse}>
      <Col lg={5} md={5} sm={12}>
        {video !== '' ? (
          <Video
            imageUrl={image}
            videoUrl={video}
            x={ImageConfiguration.x}
            y={ImageConfiguration.y}
            size={ImageConfiguration.size}
            maxWidth="460"
          />
        ) : (
          <Image
            imageUrl={image}
            x={ImageConfiguration.x}
            y={ImageConfiguration.y}
            size={ImageConfiguration.size}
            maxWidth="460"
          />
        )}
      </Col>
      <Col lg={6} md={6} sm={12}>
        <Overpass type="projectTitleColumn" htmlAttribute="h4">
          {title}
        </Overpass>
        <Overpass type="text" htmlAttribute="span">
          <HtmlRaw html={text} />
        </Overpass>
        { button && (<Button onClick={button.onClick} btnType={Button.TYPE.SECONDARY} btnText={button.btnText} />)}
      </Col>
    </ImageTextRow>
  );
};

ImageText.propTypes = {
  image: PropTypes.string,
  video: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.object,
  reverse: PropTypes.bool
};

ImageText.defaultProps = {
  video: '',
  reverse: false
};

export default withMediaQueries(ImageText);
