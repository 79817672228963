import { push } from 'redux-first-history';
import { store } from '../redux/store';

export const handleClickNavigation = (route) => {
  if (route === '') return;
  const isInternal = route.startsWith('/');
  isInternal ? store.dispatch(push(route)) : window.open(route, '_blank');
};

export const composeDefaultButton = (title, url) => ({ btnText: title, onClick: () => { handleClickNavigation(url); } });

export const goBack = (route) => {
  store.dispatch(push(route));
};
