import styled from 'styled-components';

export const CardImage = styled.div`
  width: 280px;
  height: 260px;
  top: 0;
  left: 0;
  background: url(${({ src }) => src}) no-repeat center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  @media ${props => props.theme.device.tablet} {
    width: 352px;
    height: 212px;
  }
  
  @media ${props => props.theme.device.mobile} {
    width: 100%;
    height: 233px;
  }
`;

export const CardDetails = styled.div`
  padding: 20px;
  @media ${props => props.theme.device.tablet}, @media ${props => props.theme.device.mobile} {
    padding: 20px;
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 20px;
  @media ${props => props.theme.device.tablet}, ${props => props.theme.device.mobile}  {
    margin-bottom: 10px;
  }
`;

export const CardBody = styled.div``;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary.white}
  width: 280px;
  height: 100%;
  
  @media ${props => props.theme.device.tablet} {
    width: 352px;
  }
  
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;
