import styled, { css } from 'styled-components';
import medium_linee from '../../ui/assets/img/background/medium_linee.svg';
import large_linee from '../../ui/assets/img/background/large_linee.svg';
import medium_large_linee from '../../ui/assets/img/background/medium_larg_linee.svg';

export const ContainerCollaboratorsCard = styled.div`
  margin-bottom: 70px;
  margin-left: 20px;

  @media ${props => props.theme.device.tablet} {
    margin-left: 40px;
  }
  @media ${props => props.theme.device.mobile} {
    margin-left: 20px;
    margin-bottom: 50px;
  }
`;

const funzioneCalcoloStile = ({ theme, cardType }) => {
  let style;
  switch (cardType) {
    case 'primary':
      style = css`
        background-color: ${theme.colors.secondary.bgGrayLight};
      `;
      break;
    case 'secondary':
      style = css`       
        background-color: ${theme.colors.secondary.bgYellowLight};
      `;
      break;
    case 'tertiary':
    default:
      style = css`       
        background-color: ${theme.colors.primary.white};
      `;
      break;
  }
  return style;
};

export const CardContainer = styled.div`
  max-width: 570px;
  min-height: 254px;
  height: 100%;
  position: relative;

  @media ${props => props.theme.device.tablet} {
    max-width: 704px;
    min-height: 230px;
  }
  
  @media ${props => props.theme.device.mobile} {
    max-width: 282px;
    min-height: 390px;
    margin: 0 auto;
  }
`;
export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  z-index: 11;

  ${props => funzioneCalcoloStile(props)};

  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
`;
export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: column;
  }
`;
export const CardTitle = styled.div`
  margin: 10px 0 10px 0;

  @media ${props => props.theme.device.mobile} {
    > span {
      font-size: 20px;
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;

  @media ${props => props.theme.device.mobile} {
    margin: 0 0 10px 0;
  }
`;
export const ImageProfile = styled.img`
  border-radius: 50px;
  height: 100px;
  width: 100px;

  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`;

export const ImageProfilePhone = styled.img`
  border-radius: 50px;
  height: 50px;
  width: 50px;
`;
export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-height: 92px;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    min-height: 70px;
  }
  
  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    min-height: 150px;
    justify-content: flex-end;
  }
`;

export const LinkPhone = styled.a`
  margin: 0 0 0 30px;
  padding: 0;
  align-self: center;
`;
export const Link = styled.a`
  margin-right: 30px;
  padding: 2px 20px;
  text-decoration: none;

  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`;

export const LinkInPhone = styled.div`
  display: none;
  
  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: row;
  }
`;

export const BodyContainer = styled.div`
  display: -webkit-box;
  height: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
  @media ${props => props.theme.device.tablet} {
   -webkit-line-clamp: 3;
  }
  
  @media ${props => props.theme.device.mobile} {
   -webkit-line-clamp: 6;
  }
`;

export const CardDecoration = styled.div`
  background: url(${medium_large_linee});
  position: absolute;
  top: -20px;
  left: -20px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  @media ${props => props.theme.device.tablet} {
    background: url(${large_linee});
    background-repeat: no-repeat;
  }

  @media ${props => props.theme.device.mobile} {
    background: url(${medium_linee});
    background-repeat: no-repeat;
  }
`;
