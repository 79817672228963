import {
  call, put, takeLatest, select
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { GET_PARTNERS, GET_PARTNER_DETAIL } from '../actions/partners';
import partnerspage from '../../api/partners';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';
import routes from '../../routes';

function* getPartnersPage() {
  try {
    yield put({ type: LOADER_OPEN });
    const { partners: { partner_items = [] } } = yield select(state => state.partners);
    if (partner_items.length <= 0) {
      const res = yield call(partnerspage.getPartners);
      const { data: { data: partners = {} } = {} } = res;
      yield put({ type: GET_PARTNERS._SUCCESS, partners });
    }
  } catch (error) {
    yield put({ type: GET_PARTNERS._ERROR, error });
    console.log('sagas > partners > getPartners', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getPartnersWatcher() {
  yield takeLatest(GET_PARTNERS._REQUEST, getPartnersPage);
}

function* getPartnerDetail({ partnerId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const res = yield call(partnerspage.getPartnerDetail, partnerId);
    const { data: { data: partnerdetail = {} } = {} } = res;
    if (!partnerdetail || Object.keys(partnerdetail).length === 0) yield put(push(`${routes.partners.path}`));
    yield put({ type: GET_PARTNER_DETAIL._SUCCESS, partnerdetail });
  } catch (error) {
    yield put({ type: GET_PARTNER_DETAIL._ERROR, error });
    console.log('sagas > partners > getPartnerDetail', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getPartnerDetailWatcher() {
  yield takeLatest(GET_PARTNER_DETAIL._REQUEST, getPartnerDetail);
}

export default [
  getPartnersWatcher(),
  getPartnerDetailWatcher()
];
