import styled, { css } from 'styled-components';
import Image from './index';
import linee from '../../ui/assets/img/background/linee.svg';
import lineeSmall from '../../ui/assets/img/background/linee_small.svg';

let distance = -20;

const setDecorationPosition = ({ position }) => {
  let style;
  switch (position) {
    case Image.POSITION.OUTSIDE:
      distance = -20;
      style = css`
        background: url(${linee});
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        z-index: 9;
      `;
      break;
    case Image.POSITION.INSIDE:
      distance = 0;
      style = css`
        background: url(${lineeSmall});
        z-index: 11;
        width: 160px;
        height: 40px;
      `;
      break;
    default:
      break;
  }

  return style;
};

const setDecorationPositionX = ({ x }) => {
  let style;
  switch (x) {
    case Image.X.RIGHT:
      style = css`
        right: ${distance}px;
      `;
      break;
    case Image.X.LEFT:
      style = css`
        left: ${distance}px;
      `;
      break;
    default:
      break;
  }

  return style;
};

const setDecorationPositionY = ({ y }) => {
  let style;
  switch (y) {
    case Image.Y.TOP:
      style = css`
        top: ${distance}px;
      `;
      break;
    case Image.Y.BOTTOM:
      style = css`
        bottom: ${distance}px;
      `;
      break;
    default:
      break;
  }

  return style;
};

const ImageContainer = styled.div`
  position: relative;
  align-self: baseline;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '100%')};

  &.single-image {
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
  }
`;
const ImageItem = styled.img`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 10;
  display: block;
`;

const LineeWidth = ({ size }) => {
  let style;
  switch (size) {
    case Image.SIZE.SMALL:
      style = css`
        max-width: calc(100% - 100px);
      `;
      break;
    case Image.SIZE.XSMALL:
      style = css`
        max-width: 50%;
      `;
      break;
    default:
      style = css`
        max-width: 100%;
      `;
      break;
  }

  return style;
};

const ImageDecoration = styled.div`
  position: absolute;
  ${props => setDecorationPosition(props)};
  ${props => setDecorationPositionX(props)};
  ${props => setDecorationPositionY(props)};
  ${props => LineeWidth(props)};
`;

export { ImageContainer, ImageItem, ImageDecoration };
