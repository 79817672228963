import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SectionPlan, SectionRowImages, ColDetailProject,
  SectionProjectDetail, ProjectRow, ColImageProject, RowDescriptionImages,
  ColDescriptionImages, SectionProject, SectionText, SectionImageTextReverse, ProjectPageContainer
} from './style';
import Col from '../../ui/gridSystem/Col';
import Row from '../../ui/gridSystem/Row';
import Overpass from '../../ui/typography/overpass';

import {
  Image, DetailText,
  SectionTitle
} from '../../atoms';
import {
  SectionDetail, Plans, HtmlRaw, RowImages, PageArticle
} from '../../components';
import ImageText from '../../components/ImageText';
import { GET_PROJECT } from '../../redux/actions/project';
import { composeDefaultButton } from '../../utils/common';

const Project = ({
  getProject, project
}) => {
  useEffect(() => {
    getProject();
  }, [getProject]);

  const projectPage = Object.keys(project).reduce((section, key) => {
    switch (key) {
      case 'main_box':
      case 'ending_box':
      case 'main_article':
        return {
          ...section,
          [key]: project[key][0].data
        };
      case 'plans':
      case 'central_boxes':
      case 'articles_list':
        return {
          ...section,
          [key]: project[key].map((e) => e.data)
        };
      case 'images_list':
        return {
          ...section,
          [key]: project[key][0].data.images
        };
      default:
        return {
          ...section,
          [key]: project[key]
        };
    }
  }, {});

  const RowImagesConfig = [
    {
      x: Image.X.LEFT,
      y: Image.Y.BOTTOM,
      position: Image.POSITION.OUTSIDE,
      size: Image.SIZE.SMALL
    },
    {
      lines: false
    },
    {
      x: Image.X.RIGHT,
      y: Image.Y.TOP,
      position: Image.POSITION.INSIDE
    }
  ];

  const isEven = (number) => {
    const n = number + 1;
    return n % 2 === 0;
  };

  return (
    <ProjectPageContainer>
      <SectionProject>
        { projectPage?.title && <SectionTitle title={projectPage?.title} />}
        {projectPage?.main_box
        && <PageArticle title={projectPage?.main_box?.article[0]?.data?.title} body={projectPage?.main_box?.article[0]?.data?.body} image_url={projectPage?.main_box?.file[0]?.data?.image?.url} video_file={projectPage?.main_box?.file[0]?.data?.video?.url} type={projectPage?.main_box?.file[0]?.data?.video?.url ? 'video' : 'image'} />}
      </SectionProject>
      {(projectPage?.main_article?.title || projectPage?.main_article?.body)
      && (
      <SectionText>
        <Row>
          {projectPage?.main_article?.title && (
          <Col lg={5} md={8}>
            <SectionDetail
              text={projectPage?.main_article?.title}
            />
          </Col>
          )}
          {projectPage?.main_article?.body && (
          <Col lg={5} md={12}>
            <Overpass type="text">
              <HtmlRaw html={projectPage?.main_article?.body} />
            </Overpass>
          </Col>
          )}
        </Row>
      </SectionText>
      )}
      {(projectPage?.images_list || projectPage?.articles_list)
      && (
      <SectionRowImages>
        { projectPage?.images_list && <RowImages images={projectPage.images_list} configuration={RowImagesConfig} />}
        {projectPage?.articles_list
          && (
          <RowDescriptionImages>
            {projectPage.articles_list.map((item, i) => (
              <ColDescriptionImages even={isEven(i)} lg={4} md={12} key={`ColDescriptionImages-${i}`}>
                <Overpass type="projectTitleColumn" htmlAttribute="h4">{item.title}</Overpass>
                <Overpass type="projectTextColumn">
                  <HtmlRaw html={item.body} />
                </Overpass>
              </ColDescriptionImages>
            ))}
          </RowDescriptionImages>
          )}
      </SectionRowImages>
      )}
      {projectPage?.central_boxes && (
      <SectionImageTextReverse>
        {projectPage.central_boxes.map((row, index) => (
          <ImageText
            key={`ImageText-${index}`}
            title={row?.article?.[0]?.data?.title}
            text={row?.article?.[0]?.data?.body}
            image={row?.file?.[0]?.data?.image?.url}
            video={row?.file?.[0]?.data?.video?.url}
            reverse={row?.media_position === 'Left'}
            button={composeDefaultButton(row.button.title, row.button.uri)}
          />
        ))}
      </SectionImageTextReverse>
      )}
      { projectPage?.plans
      && (
      <SectionPlan>
        <Plans plans={projectPage?.plans || []} />
      </SectionPlan>
      )}
      {projectPage?.ending_box
      && (
      <SectionProjectDetail>
        <ProjectRow>
          <Col lg={5} md={12}>
            <SectionDetail
              text={projectPage?.ending_box?.title}
            />
          </Col>
          <Col lg={7} md={12}>
            <Row>
              {projectPage?.ending_box?.articles && (
              <ColDetailProject lg={5} md={5}>
                {[...projectPage.ending_box.articles].map(({ data }, i) => (
                  <DetailText key={`DetailText-${i}`} text={data.body} number={data.title} />
                ))}
              </ColDetailProject>
              )}
              <ColImageProject lg={7} md={7}>
                { projectPage?.ending_box?.image?.url && (
                <Image
                  imageUrl={projectPage.ending_box.image.url}
                  x="right"
                  y="top"
                  maxWidth="360"
                />
                )}
              </ColImageProject>
            </Row>
          </Col>
        </ProjectRow>
      </SectionProjectDetail>
      )}
    </ProjectPageContainer>
  );
};

Project.propTypes = {
  // HOC (connect, state)
  project: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  getProject: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { project } = state.project;

    return {
      project
    };
  },
  dispatch => ({
    getProject: () => dispatch({ type: GET_PROJECT._REQUEST })
  })
)(Project);
