import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  LinkedinShareButton, FacebookShareButton, TwitterShareButton,
  LinkedinIcon, FacebookIcon, TwitterIcon
} from 'react-share';
import moment from 'moment';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import {
  SectionTitle, Image, Icon, NewsCard
} from '../../atoms';
import {
  Carousel
} from '../../components';
import {
  NewsDetailsAuthorContainer, NewsDetailsAuthorDetails, NewsDetailsAuthorInfo,
  NewsDetailsAuthorName, NewsDetailsAuthorProfession, NewsDetailsContainer,
  NewsDetailsDate, NewsDetailsHeader, NewsDetailsHeaderLeft,
  NewsDetailsHeaderRight, NewsDetailsTitle, NewsDetailsAuthorImage,
  NewsDetailsShareIconContainer, NewsDetailsShareIcons, NewsDetailsFooter,
  NewsDetailsBody, NewsDetailsBodyItem
} from './style';
import Overpass from '../../ui/typography/overpass';
import theme from '../../ui/theme';
import routes from '../../routes';
import { GET_NEWS, GET_NEWS_DETAIL } from '../../redux/actions/news';
import render_body from '../../utils/render_body';
import { goBack } from '../../utils/common';

const NewsDetail = ({
  news_id, list, selected_news, getNews,
  getNewsDetails, mediaIsPhone, mediaIsTablet, pushUrl
}) => {
  const [shareButtonVisible, setShareButtonVisible] = useState(false);

  useEffect(() => {
    getNewsDetails(news_id);
  }, [getNewsDetails, news_id]);

  useEffect(() => {
    getNews({ categoryId: null, date: null });
  }, [getNews]);

  const onShareButtonFocus = () => {
    setShareButtonVisible(true);
  };

  const onShareButtonFocusOut = () => {
    setShareButtonVisible(false);
  };

  const newsCarouselConfig = {
    slidesToShow: mediaIsPhone ? 1 : mediaIsTablet ? 3 : 4,
    slidesToScroll: mediaIsPhone ? 1 : mediaIsTablet ? 3 : 4,
    dots: mediaIsPhone || mediaIsTablet ? false : list.length > 4,
    centerPadding: '50',
    centerMode: mediaIsPhone
  };

  return (
    <NewsDetailsContainer>
      <SectionTitle title={`/   ${selected_news?.category}`} back="News" onClickBack={() => goBack(routes.news.path)} />
      <NewsDetailsHeader>
        <NewsDetailsHeaderLeft lg={6} md={12} sm={12}>
          <NewsDetailsTitle>
            <Overpass htmlAttribute="span" type={mediaIsPhone || mediaIsTablet ? 'sectionHeaderTitleMobile' : 'sectionHeaderTitle'}>{selected_news?.title}</Overpass>
          </NewsDetailsTitle>
          <NewsDetailsDate>
            <Overpass htmlAttribute="span" type="newsDate">{moment(new Date(selected_news?.date * 1000)).format('DD-MM-YYYY')}</Overpass>
          </NewsDetailsDate>
          <NewsDetailsAuthorContainer>
            {selected_news?.author && (
              <NewsDetailsAuthorDetails>
                <NewsDetailsAuthorImage src={selected_news?.author[0]?.data?.image?.url} />
                <NewsDetailsAuthorInfo>
                  <NewsDetailsAuthorName><Overpass htmlAttribute="span" type="authorName">{selected_news?.author[0]?.data?.personal_data}</Overpass></NewsDetailsAuthorName>
                  <NewsDetailsAuthorProfession><Overpass htmlAttribute="span" type="authorProfession">{selected_news?.author[0]?.data?.job_title}</Overpass></NewsDetailsAuthorProfession>
                </NewsDetailsAuthorInfo>
              </NewsDetailsAuthorDetails>
            )}
            <NewsDetailsShareIconContainer onMouseEnter={onShareButtonFocus} onMouseLeave={onShareButtonFocusOut}>
              <Icon type="icon-condividi" size={30} color={theme.colors.primary.blue5} />
              {shareButtonVisible && (
              <NewsDetailsShareIcons>
                <LinkedinShareButton url={window.location.href}><LinkedinIcon size={30} round /></LinkedinShareButton>
                <FacebookShareButton url={window.location.href}><FacebookIcon size={30} round /></FacebookShareButton>
                <TwitterShareButton url={window.location.href}><TwitterIcon size={30} round /></TwitterShareButton>
              </NewsDetailsShareIcons>
              )}
            </NewsDetailsShareIconContainer>
          </NewsDetailsAuthorContainer>
        </NewsDetailsHeaderLeft>
        <NewsDetailsHeaderRight lg={6} md={12} sm={12}>
          <Image imageUrl={selected_news?.image?.url} lines={false} />
        </NewsDetailsHeaderRight>
      </NewsDetailsHeader>
      {selected_news.page_items && (
        <NewsDetailsBody>
          {
            [...selected_news.page_items].map((item, i) => <NewsDetailsBodyItem className={`item-${item.type}`} key={`NewsDetailsBodyItem-${i}`}>{render_body(item)}</NewsDetailsBodyItem>)
          }
        </NewsDetailsBody>
      )}
      { [...list].filter(item => Number(item.id) !== Number(news_id) && item.category === selected_news?.category).length > 0 && (
        <>
          <SectionTitle title="PROTREBBE INTERESSARTI ANCHE" />
          <NewsDetailsFooter>
            <Carousel settings={newsCarouselConfig}>
              {
                [...list].filter(item => Number(item.id) !== Number(news_id) && item.category === selected_news?.category)
                  .splice(0, 8)
                  .map((item, i) => <NewsCard key={i} news={item} isInterest />)
              }
            </Carousel>
          </NewsDetailsFooter>
        </>
      )}
    </NewsDetailsContainer>
  );
};
NewsDetail.propTypes = {
  news_id: PropTypes.string.isRequired,
  //HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,

  // HOC (connect, state)
  list: PropTypes.array.isRequired,
  selected_news: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getNews: PropTypes.func.isRequired,
  getNewsDetails: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { selected_news, list } = state.news;

    return {
      selected_news,
      list
    };
  },
  dispatch => ({
    getNews: (filters) => dispatch({ type: GET_NEWS._REQUEST, filters }),
    getNewsDetails: news_id => dispatch({ type: GET_NEWS_DETAIL._REQUEST, news_id }),
    pushUrl: url => dispatch(push(url))
  })
)(withMediaQueries(NewsDetail));
