import produce from 'immer';
import { GET_PARTNERS, GET_PARTNER_DETAIL } from '../actions/partners';

const initialState = {
  partners: {},
  partnerdetail: {}
};

const partnerReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_PARTNERS._SUCCESS: {
      const { partners } = action;
      draft.partners = { ...partners };
      break;
    }
    case GET_PARTNER_DETAIL._SUCCESS: {
      const { partnerdetail } = action;
      draft.partnerdetail = { ...partnerdetail };
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default partnerReducer;
