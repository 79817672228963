import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from '../../hoc/withMediaQueries';

import {
  SectionHeader, PartnerRowImages, SectionPartner,
  PartnerRowQuote, SectionNews, NewsContainer,
  NewsButtonContainer, SectionProject, ProjectRow, ColDetailProject,
  ColImageProject, BackgroundLogo, BackgroundLines, BackgroundHeader,
  HeaderInformation, Edition, Title, ScrollDown, SectionPlan,
  BackgroundLinearGradient, TextBox, NewsCarrouselContainer,
  ScrollIcon
} from './style';
import { Row, Col } from '../../ui/gridSystem';
import {
  Image, DetailText, Button,
  SectionTitle, NewsCard
} from '../../atoms';
import {
  Quote, Carousel, SectionDetail, RecentNews, Plans, HtmlRaw, RowImages
} from '../../components';

import Overpass from '../../ui/typography/overpass';
import { GET_HOME } from '../../redux/actions/home';

import background_header from '../../ui/assets/img/background/background_header.png';
import background_logo from '../../ui/assets/img/background/background_logo.png';
import background_lines from '../../ui/assets/img/background/background_lines.png';
import { handleClickNavigation, composeDefaultButton } from '../../utils/common';
import { GET_NEWS } from '../../redux/actions/news';
import RowDetailsIcons from '../../components/RowDetailsIcons';

const Home = ({
  home, getHome, mediaIsPhone, mediaIsTablet,
  getNews, news
}) => {
  const newsCarouselConfig = {
    slidesToShow: mediaIsPhone ? 1 : mediaIsTablet ? 3 : 4,
    slidesToScroll: mediaIsPhone ? 1 : mediaIsTablet ? 3 : 4,
    dots: mediaIsPhone || mediaIsTablet ? false : news.length > 4,
    centerPadding: '50',
    centerMode: mediaIsPhone
  };

  const PartnerRowImagesConfig = [
    {
      x: Image.X.LEFT,
      y: Image.Y.BOTTOM,
      position: Image.POSITION.OUTSIDE,
      size: Image.SIZE.SMALL
    },
    {
      lines: false
    },
    {
      x: Image.X.RIGHT,
      y: Image.Y.TOP,
      position: Image.POSITION.INSIDE
    }
  ];

  useEffect(() => {
    getHome();
    getNews({ categoryId: null, date: null });
  }, [getHome, getNews]);


  const home_info = Object.keys(home).reduce((acc, key) => {
    switch (key) {
      case 'project':
      case 'partner':
      case 'witnesses':
      case 'main_block_article':
      case 'images_list':
        return {
          ...acc,
          [key]: home[key][0].data
        };
      case 'plan':
        return {
          ...acc,
          [key]: home[key].map((i) => i.data)
        };
      default:
        return {
          ...acc,
          [key]: home[key]
        };
    }
  }, {});

  return (
    <>
      <SectionHeader>
        <HeaderInformation>
          <Edition>
            <Overpass type="headerEdition" htmlAttribute="span">
              <HtmlRaw html={home_info?.banner} />
            </Overpass>
          </Edition>
          <Title>
            <Overpass type={mediaIsPhone ? 'headerTitleMobile' : 'headerTitle'} htmlAttribute="span">{home_info?.main_block_article?.title}</Overpass>
          </Title>
          <TextBox>
            <Overpass type="headerText" htmlAttribute="span">
              <HtmlRaw html={home_info?.main_block_article?.body} />
            </Overpass>
          </TextBox>
          {home_info?.main_block_button && <Button className={!home_info?.sub_main_block_button?.title ? "sub_main_block_button only_main_block" : "main_block_button" } btnType={Button.TYPE.FOURTH} minHeight={60} minWidth={mediaIsPhone ? 301 : mediaIsTablet ? 352 : 230} btnText={home_info?.main_block_button?.title} onClick={() => { handleClickNavigation(home_info?.main_block_button?.uri); }} />}
          {home_info?.sub_main_block_button?.title && <Button className="sub_main_block_button" btnType={Button.TYPE.PRIMARY} minHeight={60} minWidth={mediaIsPhone ? 301 : mediaIsTablet ? 352 : 230} btnText={home_info?.sub_main_block_button?.title} onClick={() => { handleClickNavigation(home_info?.sub_main_block_button?.uri); }} />}
          {!mediaIsPhone && (
            <ScrollDown>
              <ScrollIcon />
              <Overpass type="headerScroll" htmlAttribute="span">Scroll down</Overpass>
            </ScrollDown>
          )}
        </HeaderInformation>
        <BackgroundLinearGradient>
          <BackgroundLines src={background_lines} />
          <BackgroundLogo src={background_logo} />
          <BackgroundHeader src={background_header} />
        </BackgroundLinearGradient>
        {!!news.length && (
        <NewsCarrouselContainer>
          <RecentNews news={news} />
        </NewsCarrouselContainer>
        )}
      </SectionHeader>
      <SectionProject>
        <ProjectRow>
          <Col lg={5} md={12}>
            <SectionDetail
              title="il progetto"
              text={home_info?.project?.title}
              button={composeDefaultButton(home_info?.project?.button?.title, home_info?.project?.button?.uri)}
            />
          </Col>
          <Col lg={7} md={12}>
            <Row>
              {home_info?.project?.tips && (
              <ColDetailProject lg={5} md={5}>
                {[...home_info?.project?.tips].map((itemDetail, i) => (
                  <DetailText key={i} text={itemDetail.data.body} number={itemDetail.data.title} />
                ))}
              </ColDetailProject>
              )}
              <ColImageProject lg={7} md={7}>
                { home_info?.project?.image?.url && (
                <Image
                  imageUrl={home_info?.project?.image?.url}
                  x="right"
                  y="top"
                  maxWidth="360"
                />
                )}
              </ColImageProject>
            </Row>
          </Col>
        </ProjectRow>
      </SectionProject>
      <SectionPlan>
        <Plans plans={home_info?.plan || []} />
      </SectionPlan>
      <SectionPartner>
        <RowDetailsIcons
          title="partner"
          text={home_info?.partner?.title}
          button={composeDefaultButton(home_info?.partner?.button?.title, home_info?.partner?.button?.uri)}
          content={home_info?.partner?.content}
        />
        <PartnerRowImages>
          { home_info?.images_list?.images && <RowImages images={home_info.images_list.images} configuration={PartnerRowImagesConfig} />}
        </PartnerRowImages>
        { home_info?.witnesses
        && (
        <PartnerRowQuote>
          <Col lg={12}>
            <Quote
              image={home_info?.witnesses?.image?.url}
              title={home_info?.about_us}
              text={home_info?.witnesses?.body}
              people={home_info?.witnesses?.personal_data}
              detail={home_info?.witnesses?.job_title}
              button={composeDefaultButton(home_info?.witnesses_button?.title, home_info?.witnesses_button?.uri)}
            />
          </Col>
        </PartnerRowQuote>
        )
        }
      </SectionPartner>
      {!!news.length && (
      <SectionNews>
        <SectionTitle title="News" />
        <NewsContainer>
          <Carousel settings={newsCarouselConfig}>
            {
              [...news].splice(0, 8).map((item, i) => <NewsCard key={`HomeNewsCard-${i}`} news={item} isInterest />)
            }
          </Carousel>
        </NewsContainer>
        <NewsButtonContainer>
          <Button btnText={home_info?.news_button?.title} onClick={() => handleClickNavigation(home_info?.news_button?.uri)} btnType={Button.TYPE.SECONDARY} />
        </NewsButtonContainer>
      </SectionNews>
      )}
    </>
  );
};

Home.propTypes = {
  // HOC (withMediaQueries)
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,

  // HOC (connect, state)
  home: PropTypes.object.isRequired,
  news: PropTypes.array.isRequired,

  // HOC (connect, dispatch)
  getHome: PropTypes.func.isRequired,
  getNews: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { list: news } = state.news;
    const { home } = state.home;

    return {
      home,
      news
    };
  },
  dispatch => ({
    getHome: () => dispatch({ type: GET_HOME._REQUEST }),
    getNews: (filters) => dispatch({ type: GET_NEWS._REQUEST, filters })
  })
)(withMediaQueries(Home));
