import produce from 'immer';
import { GET_TESTIMONIALS } from '../actions/testimonials';

const initialState = {
  testimonials: {}
};

const testimonialsReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_TESTIMONIALS._SUCCESS: {
      const { testimonials } = action;
      draft.testimonials = { ...testimonials };
      break;
    }
    default:
      return state;
  }
  return draft;
});

export default testimonialsReducer;
