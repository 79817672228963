import styled from 'styled-components';
import { Row, Col } from '../../ui/gridSystem';

export const NewsDetailsContainer = styled.section`
  margin-top: 50px;
  
  .section-title {
    margin-bottom: 20px;
  }
`;

export const NewsDetailsHeader = styled(Row)`
  margin-bottom: 100px;
`;

export const NewsDetailsHeaderLeft = styled(Col)`
  @media ${props => props.theme.device.tablet}, ${props => props.theme.device.mobile} {
     margin-bottom: 50px;
  }
`;

export const NewsDetailsHeaderRight = styled(Col)``;

export const NewsDetailsTitle = styled.div`
  margin-bottom: 40px;
`;

export const NewsDetailsDate = styled.div`
  margin-bottom: 50px;
`;

export const NewsDetailsAuthorContainer = styled.div`
  padding: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted ${({ theme }) => theme.colors.secondary.grayLighter}
`;

export const NewsDetailsAuthorDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NewsDetailsAuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewsDetailsAuthorImage = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 100%;
  margin-right: 20px;
`;

export const NewsDetailsShareIconContainer = styled.div`
  position: relative;
`;

export const NewsDetailsShareIcons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  left: -80px;
  bottom: -35px;
  
  button {
    padding-right: 10px !important;
  }
`;

export const NewsDetailsAuthorName = styled.div`
  margin-bottom: 10px;
`;

export const NewsDetailsBody = styled.div`
  margin-top: 150px;
  @media ${props => props.theme.device.tablet} {
    margin-top: 100px;
  }
`;

export const NewsDetailsBodyItem = styled.div`
  margin-bottom: 100px;
  
  &.item {
    &-article{
      margin-bottom: 91px;
    }
    &-images{
      margin-bottom: 80px;
    }
    &-file_article{
      margin-bottom: 80px;
    }
    &-item-witnesses{
      margin-bottom: 50px;
    }
    &-title_articles{
      margin-bottom: 86px;
    }
  }
`;

export const NewsDetailsAuthorProfession = styled.div``;

export const NewsDetailsFooter = styled.div`
  margin-top: 10px;
  margin-bottom: 136px;
  
  @media ${props => props.theme.device.tablet} {
    margin-bottom: 50px;
  }
  
  @media ${props => props.theme.device.mobile} {
    width: 100vw;
    margin: 20px -20px 50px 0;
  }
`;
