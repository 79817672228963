import produce from 'immer';

import { LOCATION_CHANGE } from 'redux-first-history';
import {
  LOADER_OPEN,
  LOADER_CLOSE
} from '../actions';
import { GET_NAVBAR } from '../actions/navbar';

const initialState = {
  current_path: '',
  loader: {
    instances: 0,
    isSpinning: false
  },
  globalOverlayOpen: false,
  navbar: {}
};

const appReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      draft.current_path = action.payload.pathname;
      draft.globalOverlayOpen = initialState.globalOverlayOpen;
      window.scroll(0, 0);
      break;
    }
    case LOADER_OPEN: {
      const i = state.loader.instances > 0 ? state.loader.instances + 1 : 1;
      draft.loader.instances = i;
      draft.loader.isSpinning = i > 0;

      break;
    }

    case LOADER_CLOSE: {
      const i = state.loader.instances >= 1 ? state.loader.instances - 1 : 0;
      draft.loader.instances = i;
      draft.loader.isSpinning = i > 0;
      break;
    }

    case GET_NAVBAR._SUCCESS: {
      const { navbar } = action;
      draft.navbar = { ...navbar };
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default appReducer;
