import React from 'react';
import PropTypes from 'prop-types';
import {
  ContainerPlanBg,
  ButtonContainer,
  PlanBoxContainer,
  PlanBoxImage,
  ContainerPlan,
  RowPlan,
  Content,
  ColContent
} from './style';
import { Button } from '../../../atoms';
import theme from '../../../ui/theme';
import Overpass from '../../../ui/typography/overpass';
import { HtmlRaw } from '../..';
import { handleClickNavigation } from '../../../utils/common';

const colours = [theme.colors.primary.yellow, theme.colors.primary.blue5];

const PlanDesktop = ({
  plans = []
}) => (
  <ContainerPlan>
    <ContainerPlanBg>
      {[...plans].map((plan, i) => (
        <PlanBoxContainer key={`PlanBoxContainer-${i}`} first={i === 0} color={colours[i]}>
          <PlanBoxImage image={plan.image.url} />
        </PlanBoxContainer>
      ))}
    </ContainerPlanBg>
    <RowPlan className="no-gutters">
      {[...plans].map((plan, i) => (
        <ColContent lg={6} key={`PlanDesktop-${i}`}>
          <Content>
            <Overpass type="planBoxLabel">{plan.header}</Overpass>
            <Overpass type="planBoxTitle">{plan.content[0]?.data.title}</Overpass>
            <Overpass type="planBoxText"><HtmlRaw html={plan.content[0]?.data?.body} /></Overpass>
          </Content>
          { plan.button && (
            <ButtonContainer>
              <Button onClick={() => { handleClickNavigation(plan.button.uri); }} btnType={Button.TYPE.TERTIARY} btnText={plan.button.title} />
            </ButtonContainer>
          )}
        </ColContent>
      ))}
    </RowPlan>
  </ContainerPlan>
);

PlanDesktop.propTypes = {
  plans: PropTypes.array
};

export default PlanDesktop;
