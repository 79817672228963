import React from 'react';
import PropTypes from 'prop-types';
import Overpass from '../../ui/typography/overpass';
import {
  CardContainer, CardImage, CardDetails, CardTitle, CardBody
} from './style';
import { HtmlRaw } from '../../components';

const CourseOnlineCard = ({
  card
}) => {
  const { title, body, url } = card;
  return (
    <CardContainer>
      <CardImage src={url} />
      <CardDetails>
        <CardTitle><Overpass type="courseOnlineCardTitle">{title}</Overpass></CardTitle>
        <CardBody><Overpass type="courseOnlineCardBody"><HtmlRaw html={body} /></Overpass></CardBody>
      </CardDetails>
    </CardContainer>
  );
};

CourseOnlineCard.propTypes = {
  card: PropTypes.object.isRequired
};

export default CourseOnlineCard;
