import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { GET_NAVBAR } from '../actions/navbar';
import navBar from '../../api/navbar';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';

function* getNavbar() {
  try {
    yield put({ type: LOADER_OPEN });
    const res = yield call(navBar.getNavbar);
    const { data: { data: navbar = {} } = {} } = res;
    yield put({ type: GET_NAVBAR._SUCCESS, navbar });
  } catch (error) {
    yield put({ type: GET_NAVBAR._ERROR, error });
    console.log('sagas > navbar > getNavbar', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNavbarWatcher() {
  yield takeLatest(GET_NAVBAR._REQUEST, getNavbar);
}

export default [
  getNavbarWatcher()
];
