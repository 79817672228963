import styled from 'styled-components';
import { FooterHeight, FooterHeightMobile } from '../../const';
import Row from '../../ui/gridSystem/Row';
import Col from '../../ui/gridSystem/Col';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.colors.primary.blue5};
  min-height: ${FooterHeight}px;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding: 40px 60px;

  @media ${props => props.theme.device.mobile} {
    min-height: ${FooterHeightMobile}px;
  }
  @media ${props => props.theme.device.tablet}, ${props => props.theme.device.mobile} {
    padding: 40px 20px;
  }
`;

const FooterRow = styled(Row)`
  display: -ms-flexbox;
  width: 100%;
  margin: 0 auto;
`;

const ContainerLogo = styled(Col)`
  @media ${props => props.theme.device.tablet}, ${props => props.theme.device.mobile} {
    margin-bottom: 20px;
  }
`;

const ContainerText = styled(Col)`
  .row {
    > div {
      &:first-child{
        margin-left: 0;
      }
    }
  }
`;

const ColumnText = styled(Col)`
  display: flex;
  flex-direction: column;

  >div {
    span {
      display: block;
      margin-bottom: 10px;
    }
    + div{
      margin-top: 20px;
    }
  }

  &:nth-last-of-type(1) {
    @media ${props => props.theme.device.mobile} {
      margin-top: 40px;
    }
  }
`;

const Socials = styled.div`
  display: flex;
  span {
    +span{
      margin-left: 11px;
    }
  }
`;

const BoxText = styled.div`
  margin- top: 50px;

  &:last-of-type {
    @media ${props => props.theme.device.tablet} {
      margin-top: auto
      >div {
        span {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export {
  FooterWrapper,
  FooterRow,
  ContainerLogo,
  ContainerText,
  ColumnText,
  Socials,
  BoxText
};
