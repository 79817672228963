import {
  call, put, takeLatest, select
} from 'redux-saga/effects';
import { GET_HOME } from '../actions/home';
import homepage from '../../api/home';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';

function* getHomePage() {
  try {
    yield put({ type: LOADER_OPEN });
    const { home: homeStored } = yield select(state => state.home);
    if (Object.keys(homeStored).length === 0) {
      const res = yield call(homepage.getHome);
      const { data: { data: home = {} } = {} } = res;
      yield put({ type: GET_HOME._SUCCESS, home });
    }
  } catch (error) {
    yield put({ type: GET_HOME._ERROR, error });
    console.log('sagas > home > getHome', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getHomeWatcher() {
  yield takeLatest(GET_HOME._REQUEST, getHomePage);
}

export default [
  getHomeWatcher()
];
