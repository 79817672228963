import React from 'react';
import { HtmlRaw, PageArticle, Quote, RowImages } from '../components';
import { Image, Video } from '../atoms';
import ImageText from '../components/ImageText';
import RowData from '../components/RowData';
import { PageArticleTypes } from '../components/PageArticle';

const rowImagesConfig = [
  {
    x: Image.X.LEFT,
    y: Image.Y.BOTTOM,
    position: Image.POSITION.OUTSIDE,
    size: Image.SIZE.SMALL
  },
  {
    lines: false
  },
  {
    x: Image.X.RIGHT,
    y: Image.Y.TOP,
    position: Image.POSITION.INSIDE
  }
];

const imagesConfiguration = images =>
  images.length >= 3 ? rowImagesConfig : [];

const render_body = item => {
  const { type, data } = item;
  switch (type) {
    case 'body':
      return <HtmlRaw html={data.body} />;
    case 'file':
      return data.video ? (
        <Video
          imageUrl={data.image.url}
          videoUrl={data.video.url}
          lines={false}
        />
      ) : (
        <Image imageUrl={data.image.url} lines={false} />
      );
    case 'file_article':
      return (
        <ImageText
          title={data?.article[0]?.data?.title}
          text={data?.article[0]?.data?.body}
          image={data?.file[0]?.data?.image?.url}
          video={data?.file[0]?.data?.video?.url}
          reverse={data?.media_position === 'Right'}
        />
      );
    case 'article':
      return <PageArticle title={data.title} body={data.body} />;
    case 'images':
      return (
        <RowImages
          configuration={imagesConfiguration(data.images)}
          images={data.images}
        />
      );
    case 'title_articles':
      return (
        <RowData title={data.title} data={data.articles.map(a => a.data)} />
      );
    case 'witnesses':
      return (
        <Quote
          image={data?.image?.url}
          text={data?.body}
          people={data?.personal_data}
          detail={data?.job_title}
        />
      );
    case 'faq_item':
      return (
        <PageArticle
          title={data.question}
          body={data.answer}
          type={PageArticleTypes.FAQ}
        />
      );
    default:
      return <></>;
  }
};

export default render_body;
