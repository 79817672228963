import './ui/scss/bootstrap/bootstrap-grid.min.css';
import './ui/scss/app.scss';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import theme from './ui/theme';
import { history } from './redux/store';
import routes from './routes';
import { GET_NAVBAR } from './redux/actions/navbar';

import { GlobalLayout, Loader } from './components';
import ModalsPortal from './components/Modals/ModalsPortal';
/* Pages */
import {
  Sample,
  Home,
  Testimonials,
  News,
  NewsDetail,
  Partners,
  PartnerDetail,
  OnlineCourse,
  ClassroomCourse,
  Project,
  Faq
} from './pages';

const App = ({ getNavbar }) => {
  useEffect(() => {
    getNavbar();
  }, [getNavbar]);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Loader />
        <GlobalLayout>
          <Switch>
            <Route path={routes.home.path} exact render={() => <Home />} />
            <Route
              path={routes.testimonials.path}
              render={() => <Testimonials />}
            />
            <Route
              path={`${routes.news.path}/:id`}
              exact
              render={({ match: { params: { id = '' } = {} } = {} }) => (
                <NewsDetail news_id={id} />
              )}
            />
            <Route path={routes.news.path} render={() => <News />} />
            <Route
              path={`${routes.partners.path}/:id`}
              exact
              render={({ match: { params: { id = '' } = {} } = {} }) => (
                <PartnerDetail partner_id={id} />
              )}
            />
            <Route path={routes.partners.path} render={() => <Partners />} />
            <Route
              path={routes.onlineCourse.path}
              render={() => <OnlineCourse />}
            />
            <Route
              path={routes.classroomCourse.path}
              render={() => <ClassroomCourse />}
            />
            <Route path={routes.project.path} render={() => <Project />} />
            <Route path={routes.sample.path} render={() => <Sample />} />
            <Route path={routes.faq.path} render={() => <Faq />} />
            <Redirect to={routes.home.path} />
          </Switch>
        </GlobalLayout>
      </Router>
      <ModalsPortal.Target />
    </ThemeProvider>
  );
};

App.propTypes = {
  // HOC (connect, dispatch)
  getNavbar: PropTypes.func.isRequired
};

export default connect(
  () => ({}),
  dispatch => ({
    getNavbar: () => dispatch({ type: GET_NAVBAR._REQUEST })
  })
)(App);
