import styled from 'styled-components';
import { Col } from '../../ui/gridSystem';

export const PartnersContainer = styled.div`
  margin-top: 50px;

  .section-title {
    margin-bottom: 15px;
  }
`;

export const Article = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 45px;

  @media ${props => props.theme.device.tablet} {
    padding: 0 80px;
  }
`;

export const SectionPartners = styled.section`
  margin-top: 95px;
`;

export const Logo = styled.img`
  cursor: pointer;
  max-height: 100%;
`;

export const ColumnPartner = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 80px;
  margin-bottom: 100px;
  height: 100px;

  @media ${props => props.theme.device.tablet},
    ${props => props.theme.device.mobile} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const BtnsWrapper = styled.div`
  @media ${props => props.theme.device.tablet} {
    padding: 0 80px;
  }
`;

export const ArticleWrapper = styled.div`
  margin-top: 70px;
`;
