import styled from 'styled-components';
import theme from '../../ui/theme';

export const SectionDetailContainer = styled.div`
  div {
    &:nth-child(2) {
      margin-top: 28px;
    }
  }

  a {
    color: ${theme.colors.secondary.orange};
  }

  button {
    margin-top: 40px;

    @media ${props => props.theme.device.tablet} {
      margin: 40px 0 50px 0;
    }

    @media ${props => props.theme.device.mobile} {
      margin: 40px 0;
    }
  }

  &.flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media ${props => props.theme.device.mobile},
      ${props => props.theme.device.tablet} {
      flex-direction: column;
    }

    & button {
      margin-top: unset;
    }
  }
`;
