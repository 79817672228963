import styled from 'styled-components';

export const SectionTitleContainer = styled.div.attrs(() => ({
  className: 'section-title'
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Back = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
`;

export const Title = styled.div`
  text-transform: uppercase;
  margin-right: 10px;
  
  > span {
    position: relative;
    
    &::after {
      content: '';
      display: inline-block;
      height: 2px;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.secondary.grayLight};
      width: 30px;
      top: 50%;
      right: -40px;
      transform: translate(0,-50%);
    }
  }
`;
