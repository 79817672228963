import axios from 'axios';

export const shouldUseMock = () => process.env.REACT_APP_DEPLOY_ENV?.trim() === 'LOCAL' && Number(process.env.REACT_APP_ENABLE_MOCK) === 1;

const isLocalHost = window.location.hostname === 'localhost';

const ENV_BASE_URL = {
  DEV: null,
  PREPROD: 'palestredigitali:8080',
  STAGE: 'https://stage-api.palestredigitali.it',
  PROD: 'https://api.palestredigitali.it',

  DEFAULT: `${window.location.origin}/api`
};

export const apiHost = ENV_BASE_URL[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_URL.DEFAULT;

export const baseUrl = '/api/';

const checkForLocalhostRules = (url = '') => {
  if (isLocalHost) {
    const baseurl = `http://${window.location.hostname}`;
    const port = '2222';
    return `${baseurl}${port}/api${url}`;
  }

  return `${apiHost}${url}`;
};

const axiosApi = axios.create({
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosApi.interceptors.request.use(req => {
  const token = 'YXBpX3VzZXI6Y2lhbw==';
  req.headers.Authorization = `Basic ${token}`;
  return req;
});

axiosApi.interceptors.response.use(
  res => res,
  error => error
);

export function apiCall(url, method, body = null, options = null) {
  return axiosApi[method.toLowerCase()](checkForLocalhostRules(url), body, options);
}
