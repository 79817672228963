import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { FaqContainer, FaqEditorialContent, FaqsBodyItem } from './style';
import { GET_FAQ } from '../../redux/actions/faq';
import { SectionTitle } from '../../atoms';
import render_body from '../../utils/render_body';

const Faq = ({ getFaq, title, faq_items }) => {
  useEffect(() => {
    getFaq();
  }, [getFaq]);

  return (
    <FaqContainer>
      <SectionTitle title={title} />
      <FaqEditorialContent>
        {faq_items?.length > 0 && (
          <>
            {faq_items.map((faq, i) => (
              <FaqsBodyItem
                className={`item-${faq.type}`}
                key={`faq-item-${i}`}
              >
                {render_body(faq)}
              </FaqsBodyItem>
            ))}
          </>
        )}
      </FaqEditorialContent>
    </FaqContainer>
  );
};

Faq.propTypes = {
  // HOC (connect, state)
  title: PropTypes.string,
  faq_items: PropTypes.array,

  // HOC (connect, dispatch)
  getFaq: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      faq: { faq_items = [], title }
    } = state.faq;

    return {
      title,
      faq_items
    };
  },
  dispatch => ({
    getFaq: () => dispatch({ type: GET_FAQ._REQUEST })
  })
)(withMediaQueries(Faq));
