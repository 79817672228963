import styled from 'styled-components';
import Row from '../../ui/gridSystem/Row';
import Col from '../../ui/gridSystem/Col';

const sectionSpace = 50;
const sectionHeight = 650;

const ProjectPageContainer = styled.div`
  padding-top: ${sectionSpace}px;
`;

const SectionPlan = styled.section`
`;

const SectionRowImages = styled.section`
  margin-bottom: calc(${sectionSpace}px + 20px);
  @media ${props => props.theme.device.tablet} {
    margin: 0 20px 120px;
  }
  @media ${props => props.theme.device.mobile} {
    margin-left: 20px;
  }
`;

const ColImageProject = styled(Col)`
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
  @media ${props => props.theme.device.tablet} {
    margin-top: 40px;
    justify-content: center;
  }
  @media ${props => props.theme.device.mobile} {
    margin-top: 60px;
  }
`;

const ColDetailProject = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    + div {
      margin-top: 30px;
    }
  }
`;

const SectionProjectDetail = styled.section`
  min-height: ${sectionHeight}px;
  height: ${sectionHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${sectionSpace}px 0;

  @media ${props => props.theme.device.mobile} {
    height: auto;
    min-height: auto;
    padding: 86px 0 20px;
  }
  >.row {
    > div {
      &:first-child {
        margin-bottom: 120px;
      }
    }
  }
  @media ${props => props.theme.device.tablet} {
    height: auto;
    min-height: auto;
  }
`;

const ProjectRow = styled(Row)``;

const RowDescriptionImages = styled(Row)`
  min-height: 300px;
  @media ${props => props.theme.device.tablet},${props => props.theme.device.mobile} {
    margin-top: 10px;
  }
`;

const ColDescriptionImages = styled(Col)`
  display: flex;
  flex-direction: column;

  h4 {
    margin-top: 70px;
    margin-bottom: 20px;
    @media ${props => props.theme.device.tablet},${props => props.theme.device.mobile} {
      margin-top: 0;
    }
  }

  span{
    margin-top: 20px;
  }

  @media ${props => props.theme.device.tablet},${props => props.theme.device.mobile} {
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: ${props => (props.even ? 'row-reverse' : 'row')};
    text-align: ${props => (props.even ? 'right' : 'left')};
  }
`;

const SectionProject = styled.section`
  margin-bottom: calc(${sectionSpace}px + 95px);
  
  .section-title {
    margin-bottom: 30px;
  }
  @media ${props => props.theme.device.tablet} {
    padding: 0 80px;
    margin-bottom: calc(${sectionSpace}px * 2);
  }
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 100px;
  }
`;

const SectionText = styled.section`
  margin-bottom: ${sectionSpace}px;
  .row{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
      &:first-child{
        @media ${props => props.theme.device.tablet}, ${props => props.theme.device.mobile} {
          margin-bottom: 40px;
        }
      }
    }
  }
  @media ${props => props.theme.device.tablet} {
    margin-bottom: calc(${sectionSpace}px + 20px);
  }
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 73px;
  }
`;

const SectionImageTextReverse = styled.section`
  margin-bottom: 30px;
  @media ${props => props.theme.device.tablet} {
    margin-bottom: ${sectionSpace}px;
  }
`;

export {
  SectionPlan, SectionRowImages, ColImageProject,
  ColDetailProject, SectionProjectDetail, ProjectRow, RowDescriptionImages,
  ColDescriptionImages, SectionProject, SectionText, SectionImageTextReverse, ProjectPageContainer
};
