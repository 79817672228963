import styled, { css } from 'styled-components';
import { NavBarHeight } from '../../../const';

const Header = styled.header`
  position: fixed;
  width: 100%;
  z-index:  ${props => props.theme.zIndex.headerFooter};
  height: ${NavBarHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.primary.white};
  background-color: ${props => (props.transparent ? 'transparent' : props.theme.colors.primary.blue5)}};
  transition: all 0.6s ease;
  `;

const HeaderWrapper = styled.div`
  padding: 0 22px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const MenuContainer = styled.div`
  position: fixed;
  top: ${NavBarHeight}px;
  bottom: 0;
  left: 100%;
  z-index: ${({ theme }) => theme.zIndex.menu};
  background-color: ${props => props.theme.colors.primary.blue5};
  width: 100vw;
  height: calc(100vh - ${NavBarHeight}px);
  padding: 30px 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  ${({ isOpen }) => isOpen && css`
    left: 0;
  `}
  transition: all 250ms ease-in-out;
`;

const MenuList = styled.div``;

const MenuItemContainer = styled.div``;

const MenuItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-transform: uppercase;
`;

const MenuSubItem = styled(MenuItem)`
  background-color: ${props => props.theme.colors.secondary.lightViolet};
  width: 100vw;
  + div {
    border-top: 1px solid  ${props => props.theme.colors.primary.blue5};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Link = styled.a`
  text-transform: uppercase;
  padding: 20px 0;
`;

const Socials = styled.div`
  display: flex;
  span {
    color: ${props => props.theme.colors.primary.white};
    + span{
      margin-left: 15px;
    }
  }
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export {
  Header,
  HeaderWrapper,
  MenuContainer,
  MenuList,
  MenuItemContainer,
  MenuItem,
  MenuSubItem,
  ButtonsContainer,
  Link,
  Socials
};
