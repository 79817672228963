import produce from 'immer';
import { GET_PROJECT } from '../actions/project';

const initialState = {
  project: {}
};

const projectReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_PROJECT._SUCCESS: {
      const { project } = action;
      draft.project = { ...project };
      break;
    }

    default:
      return state;
  }
  return draft;
});

export default projectReducer;
