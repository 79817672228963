import styled, { css } from 'styled-components';

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  
  font-family: 'Overpass';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primary.black5};

  @media ${props => props.theme.device.mobile} {
      font-size: 12px;
    }
  
  div[class*="-placeholder"] {
    font-weight: normal;
    font-style: italic;
    color: ${props => props.theme.colors.secondary.grayLight};
  }
  
  div[class*="-menu"] {
    font-weight: normal;
  }

  div[class*="-indicatorContainer"] {
    color: ${props => props.theme.colors.primary.blue5};
    
    &:first-child {
      padding-right: 0;
    }

    &:hover {
      color: ${props => props.theme.colors.primary.blue5};
    }
  }
  
  div[class*="ValueContainer"] {
    div[class*="singleValue"] {
      color: ${props => props.theme.colors.primary.blue5};
    }
  }
  
  ${props => props.readonly && css`
    pointer-events: none;
    div[class*="control"] {
      border: none;
      background-color: transparent;
    }
    
    div[class*="ValueContainer"] {
      padding: 0;
    }
    
    div[class*="IndicatorsContainer"] {
      display: none;
    }
    
  `}
  
  ${({ isSearchType }) => isSearchType && css`
    div[class*="control"] {
      cursor: auto;
    }
    div[class*="-indicatorContainer"] {
      cursor: pointer;
    }
    span ~ div[class*="-indicatorContainer"] {
      display: none;
    }
  `}
`;

export { SelectContainer };
